* {
  font-family: "Almarai", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* Border Radius */
/* Transitions */
/* Fonts size */
:root {
  --neutral-white: #ffffff;
  --neutral-black: #000000;
  --neutral: var(--neutral-white);
  --invert-neutral: var(--neutral-black);
  --gray-50: #f8f8f8;
  --gray-100: #f3f3f3;
  --gray-200: #ededed;
  --gray-300: #e2e2e2;
  --gray-400: #c7c7c7;
  --gray-500: #999999;
  --gray-600: #7c7c7c;
  --gray-700: #525252;
  --gray-800: #383838;
  --gray-900: #171717;
  --blue-50: #f7fbfd;
  --blue-100: #edf6fd;
  --blue-200: #e3f1fd;
  --blue-300: #c9e7fc;
  --blue-400: #70b6f0;
  --blue-500: #0289f7;
  --blue-600: #007be0;
  --blue-700: #0070cc;
  --blue-800: #005ca3;
  --blue-900: #004880;
  --green-50: #f3fcf5;
  --green-100: #e4f5e9;
  --green-200: #daf0e1;
  --green-300: #cae5d4;
  --green-400: #b6dec5;
  --green-500: #59ba8b;
  --green-600: #30a66d;
  --green-700: #278f5e;
  --green-800: #16794c;
  --green-900: #173b2c;
  --red-50: #fff7f7;
  --red-100: #fff0f0;
  --red-200: #fcd7d7;
  --red-300: #f9c6c6;
  --red-400: #eb9091;
  --red-500: #e03636;
  --red-600: #cc2929;
  --red-700: #b52a2a;
  --red-800: #941f1f;
  --red-900: #6b1515;
  --orange-50: #fff9f5;
  --orange-100: #fff1e7;
  --orange-200: #fce6d5;
  --orange-300: #f7d6bd;
  --orange-400: #f0b58b;
  --orange-500: #e86c13;
  --orange-600: #d45a08;
  --orange-700: #bd3e0c;
  --orange-800: #9e3513;
  --orange-900: #6b2711;
  --amber-50: #fdfaed;
  --amber-100: #fcf3cf;
  --amber-200: #f7e28d;
  --amber-300: #f5d261;
  --amber-400: #f2be3a;
  --amber-500: #e79913;
  --amber-600: #db7706;
  --amber-700: #b35309;
  --amber-800: #91400d;
  --amber-900: #763813;
  --yellow-50: #fffcef;
  --yellow-100: #fff7d3;
  --yellow-200: #f7e9a8;
  --yellow-300: #f5e171;
  --yellow-400: #f2d14b;
  --yellow-500: #edba13;
  --yellow-600: #d1930d;
  --yellow-700: #ab6e05;
  --yellow-800: #8c5600;
  --yellow-900: #733f12;
  --cyan-50: #f5fbfc;
  --cyan-100: #e0f8ff;
  --cyan-200: #b3ecfc;
  --cyan-300: #94e6ff;
  --cyan-400: #6bd3f2;
  --cyan-500: #34bae3;
  --cyan-600: #32a4c7;
  --cyan-700: #267a94;
  --cyan-800: #125c73;
  --cyan-900: #164759;
  --teal-50: #f0fdfa;
  --teal-100: #e6f7f4;
  --teal-200: #bae8e1;
  --teal-300: #97ded4;
  --teal-400: #73d1c4;
  --teal-500: #36baad;
  --teal-600: #0b9e92;
  --teal-700: #0f736b;
  --teal-800: #115c57;
  --teal-900: #114541;
  --violet-50: #fbfaff;
  --violet-100: #f5f2ff;
  --violet-200: #e5e1fa;
  --violet-300: #dad2f7;
  --violet-400: #bdb1f0;
  --violet-500: #6846e3;
  --violet-600: #5f46c7;
  --violet-700: #4f3da1;
  --violet-800: #392980;
  --violet-900: #251959;
  --pink-50: #fff7fc;
  --pink-100: #feeef8;
  --pink-200: #f8e2f0;
  --pink-300: #f2d4e6;
  --pink-400: #e9c4da;
  --pink-500: #e34aa6;
  --pink-600: #cf3a96;
  --pink-700: #9c2671;
  --pink-800: #801458;
  --pink-900: #570f3e;
  --purple-50: #fdfaff;
  --purple-100: #f9f0ff;
  --purple-200: #f1e5fa;
  --purple-300: #e9d6f5;
  --purple-400: #d6c1e6;
  --purple-500: #9c45e3;
  --purple-600: #8642c2;
  --purple-700: #6e399d;
  --purple-800: #5c2f83;
  --purple-900: #401863;
  --white-overlay-50: rgba(255, 255, 255, 0.09);
  --white-overlay-100: rgba(255, 255, 255, 0.18);
  --white-overlay-200: rgba(255, 255, 255, 0.27);
  --white-overlay-300: rgba(255, 255, 255, 0.36);
  --white-overlay-400: rgba(255, 255, 255, 0.45);
  --white-overlay-500: rgba(255, 255, 255, 0.54);
  --white-overlay-600: rgba(255, 255, 255, 0.63);
  --white-overlay-700: rgba(255, 255, 255, 0.72);
  --white-overlay-800: rgba(255, 255, 255, 0.81);
  --white-overlay-900: rgba(255, 255, 255, 0.9);
  --black-overlay-50: rgba(0, 0, 0, 0.09);
  --black-overlay-100: rgba(0, 0, 0, 0.18);
  --black-overlay-200: rgba(0, 0, 0, 0.27);
  --black-overlay-300: rgba(0, 0, 0, 0.36);
  --black-overlay-400: rgba(0, 0, 0, 0.45);
  --black-overlay-500: rgba(0, 0, 0, 0.54);
  --black-overlay-600: rgba(0, 0, 0, 0.63);
  --black-overlay-700: rgba(0, 0, 0, 0.72);
  --black-overlay-800: rgba(0, 0, 0, 0.81);
  --black-overlay-900: rgba(0, 0, 0, 0.9);
  --linear-black: linear-gradient(
  	to bottom,
  	rgba(46, 46, 46, 0.18) 0%,
  	rgba(36, 36, 36, 0.14) 100%
  );
  --linear-blue: linear-gradient(
  	to bottom,
  	rgba(17, 142, 245, 0.067) 0%,
  	rgba(7, 127, 247, 0.029) 100%
  );
  --angular-white: conic-gradient(rgba(255, 255, 255, 1) 72.38%, rgba(255, 255, 255, 1) 99.87%);
  --angular-black: conic-gradient(rgba(56, 56, 56, 0.22) 72.38%, rgba(56, 56, 56, 0.22) 99.87%);
  --angular-green: conic-gradient(
  	rgba(23, 117, 75, 0.092) 72.38%,
  	rgba(23, 117, 75, 0.092) 99.87%
  );
  --angular-red: conic-gradient(
  	rgba(205, 41, 41, 0.804) 72.38%,
  	rgba(205, 41, 41, 0.804) 99.87%
  );
  --angular-blue: conic-gradient(rgba(0, 110, 219, 0) 72.38%, rgba(0, 110, 219, 0) 99.87%);
}

:root {
  --font-stack: "InterVariable", "Inter", "-apple-system", "BlinkMacSystemFont", "Segoe UI",
  	"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  	sans-serif;
  --text-tiny: 11px;
  --text-2xs: 12px;
  --text-xs: 12px;
  --text-sm: 13px;
  --text-md: 13px;
  --text-base: 14px;
  --text-lg: 16px;
  --text-xl: 18px;
  --text-2xl: 20px;
  --text-3xl: 24px;
  --text-4xl: 26px;
  --text-5xl: 28px;
  --text-6xl: 32px;
  --text-7xl: 40px;
  --text-8xl: 44px;
  --text-9xl: 48px;
  --text-10xl: 52px;
  --text-11xl: 56px;
  --text-12xl: 64px;
  --weight-regular: 420;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-black: 800;
  --text-line-height-3xl: 115%;
  --text-line-height-4xl: 160%;
  --text-line-height-7xl: 140%;
  --text-line-height-12xl: 130%;
  --text-line-height-14xl: 120%;
  --para-line-height-2-xs: 160%;
  --para-line-height-sm: 150%;
  --para-line-height-2xl: 148%;
  --para-line-height-3xl: 140%;
  --heading-color: var(--gray-900);
  --text-neutral: var(--gray-900);
  --text-color: var(--gray-800);
  --text-muted: var(--gray-700);
  --text-light: var(--gray-600);
  --text-dark: var(--fg-color);
}

:root {
  --shadow-xs: rgba(0, 0, 0, 0.05) 0px 0.5px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
  	rgba(0, 0, 0, 0.05) 0px 2px 4px 0px;
  --shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-base: 0px 0px 1px rgba(0, 0, 0, 0.45), 0px 1px 2px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 0.5px 2px rgba(0, 0, 0, 0.15),
  	0px 2px 3px rgba(0, 0, 0, 0.16);
  --shadow-lg: 0px 0px 1px rgba(0, 0, 0, 0.35), 0px 6px 8px -4px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 0px 1px rgba(0, 0, 0, 0.19), 0px 1px 2px rgba(0, 0, 0, 0.07),
  	0px 6px 15px -5px rgba(0, 0, 0, 0.11);
  --shadow-2xl: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.05),
  	0px 10px 24px -3px rgba(0, 0, 0, 0.1);
  --focus-default: 0px 0px 0px 2px #c9c9c9;
  --focus-blue: 0px 0px 0px 2px #65b9fc;
  --focus-green: 0px 0px 0px 2px #5bb98c;
  --focus-yellow: 0px 0px 0px 2px #fff0ad;
  --focus-red: 0px 0px 0px 2px #eb9091;
  --custom-status: 0px 0px 0px 1.5px #ffffff;
  --custom-shadow-sm: 0px 1px 4px rgba(0, 0, 0, 0.1);
  --drop-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px rgba(0, 0, 0, 0),
  	0px 2px 4px rgba(0, 0, 0, 0.05);
}

:root {
  --input-padding: 6px 8px;
  --dropdown-padding: 4px 8px;
  --grid-padding: 10px 8px;
  --disabled-input-padding: 3px 8px;
  --number-card-padding: 8px 8px 8px 12px;
}

:root {
  --border-radius-tiny: 4px;
  --border-radius-sm: 8px;
  --border-radius: 8px;
  --border-radius-md: 10px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-2xl: 20px;
  --border-radius-full: 999px;
}

.font-size-xs {
  font-size: 0.75rem;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-base {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}

.font-size-4xl {
  font-size: 2.25rem;
}

.font-size-5xl {
  font-size: 3rem;
}

.font-size-6xl {
  font-size: 4rem;
}

:root,
[data-theme=light] {
  --brand-color: var(--primary);
  --padding-xs: 5px;
  --padding-sm: 6px;
  --padding-md: 15px;
  --padding-lg: 20px;
  --padding-xl: 30px;
  --padding-2xl: 40px;
  --margin-xs: 5px;
  --margin-sm: 10px;
  --margin-md: 15px;
  --margin-lg: 20px;
  --margin-xl: 30px;
  --margin-2xl: 40px;
  --modal-shadow: var(--shadow-md);
  --card-shadow: var(--shadow-sm);
  --btn-shadow: var(--shadow-xs);
  --navbar-height: 48px;
  --icon-fill: transparent;
  --icon-fill-bg: var(--fg-color);
  --icon-stroke: var(--gray-800);
  --bg-blue: var(--blue-100);
  --bg-light-blue: var(--blue-50);
  --bg-dark-blue: var(--blue-300);
  --bg-green: var(--green-100);
  --bg-yellow: var(--yellow-100);
  --bg-orange: var(--orange-100);
  --bg-red: var(--red-100);
  --bg-gray: var(--gray-100);
  --bg-grey: var(--gray-100);
  --bg-light-gray: var(--gray-100);
  --bg-dark-gray: var(--gray-400);
  --bg-darkgrey: var(--gray-400);
  --bg-purple: var(--purple-100);
  --bg-pink: var(--pink-50);
  --bg-cyan: var(--cyan-50);
  --text-on-blue: var(--blue-700);
  --text-on-light-blue: var(--blue-600);
  --text-on-dark-blue: var(--blue-800);
  --text-on-green: var(--green-800);
  --text-on-yellow: var(--yellow-700);
  --text-on-orange: var(--orange-700);
  --text-on-red: var(--red-700);
  --text-on-gray: var(--gray-700);
  --text-on-grey: var(--gray-700);
  --text-on-darkgrey: var(--gray-800);
  --text-on-dark-gray: var(--gray-800);
  --text-on-light-gray: var(--gray-800);
  --text-on-purple: var(--purple-700);
  --text-on-pink: var(--pink-700);
  --text-on-cyan: var(--cyan-700);
  --alert-text-danger: var(--red-600);
  --alert-text-warning: var(--yellow-700);
  --alert-text-info: var(--blue-700);
  --alert-text-success: var(--green-700);
  --alert-bg-danger: var(--red-50);
  --alert-bg-warning: var(--yellow-50);
  --alert-bg-info: var(--blue-50);
  --alert-bg-success: var(--green-100);
  --bg-color: white;
  --fg-color: white;
  --subtle-accent: var(--gray-50);
  --subtle-fg: var(--gray-100);
  --navbar-bg: white;
  --fg-hover-color: var(--gray-100);
  --card-bg: var(--fg-color);
  --disabled-text-color: var(--gray-600);
  --disabled-control-bg: var(--gray-50);
  --control-bg: var(--gray-100);
  --control-bg-on-gray: var(--gray-200);
  --awesomebar-focus-bg: var(--fg-color);
  --modal-bg: white;
  --toast-bg: var(--modal-bg);
  --popover-bg: white;
  --awesomplete-hover-bg: var(--control-bg);
  --btn-primary: var(--gray-900);
  --btn-default-bg: var(--gray-100);
  --btn-default-hover-bg: var(--gray-300);
  --border-primary: var(--gray-900);
  --sidebar-select-color: var(--gray-100);
  --scrollbar-thumb-color: var(--gray-400);
  --scrollbar-track-color: var(--gray-200);
  --shadow-inset: inset 0px -1px 0px var(--gray-300);
  --border-color: var(--gray-200);
  --dark-border-color: var(--gray-300);
  --table-border-color: var(--gray-200);
  --highlight-color: var(--gray-50);
  --yellow-highlight-color: var(--yellow-50);
  --btn-group-border-color: var(--gray-300);
  --placeholder-color: var(--gray-50);
  --highlight-shadow: 1px 1px 10px var(--blue-50), 0px 0px 4px var(--blue-600);
  --code-block-bg: var(--gray-900);
  --code-block-text: var(--gray-400);
  --primary-color: var(--gray-900);
  --btn-height: 28px;
  --input-height: 28px;
  --input-disabled-bg: var(--gray-200);
  --checkbox-right-margin: var(--margin-xs);
  --checkbox-size: 14px;
  --checkbox-color: var(--neutral-black);
  --checkbox-focus-shadow: 0 0 0 2px var(--gray-300);
  --checkbox-gradient: linear-gradient(180deg, var(--primary) -124.51%, var(--primary) 100%);
  --checkbox-disabled-gradient: linear-gradient(
  	180deg,
  	var(--disabled-control-bg) -124.51%,
  	var(--disabled-control-bg) 100%
  );
  --switch-bg: var(--gray-300);
  --diff-added: var(--green-100);
  --diff-removed: var(--red-100);
  --right-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.25 7.5L4.75 4L1.25 0.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  --left-arrow-svg: url("data: image/svg+xml;utf8, <svg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 9.5L4 6l3.5-3.5' stroke='%231F272E' stroke-linecap='round' stroke-linejoin='round'></path></svg>");
}

:root {
  --light: #e4e4e5;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-base: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.875rem;
  --font-size-4xl: 2.5rem;
  --font-size-5xl: 3rem;
  --font-size-6xl: 4rem;
  --card-border-radius: 0.75rem;
}

:root {
  --highlighted-text-bg-color: #4169E1;
}

/* Hero Section */
#hero .section-text {
  margin: auto;
  width: 40%;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 38px;
}
@media (min-width: 1200px) and (max-width: 1600px) {
  #hero .section-text {
    width: 60%;
    row-gap: 23px;
  }
}
@media (max-width: 1199.98px) {
  #hero .section-text {
    width: 80%;
    row-gap: 23px;
  }
}
@media (max-width: 767.98px) {
  #hero .section-text {
    width: 100%;
  }
}
#hero .section-text h1 {
  font-size: 3.75rem;
  line-height: 1.2;
}
@media (max-width: 991.98px) {
  #hero .section-text h1 {
    font-size: 2.4rem;
  }
}
#hero .section-text p {
  font-size: 1rem;
  font-weight: 400;
}
@media (max-width: 991.98px) {
  #hero .section-text p {
    font-size: 0.9rem;
  }
}
#hero .section-text .action-buttons-container {
  display: flex;
  width: 76%;
  margin: auto;
  gap: 8px;
}
@media (max-width: 991.98px) {
  #hero .section-text .action-buttons-container {
    gap: 10px;
    flex-direction: column;
    width: 80%;
  }
}
#hero .section-text .action-buttons-container a {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dashboard Section */
#dashboard-preview .preview-container {
  width: 100%;
  height: 680px;
  background-color: #5a64bf;
  border-radius: 25px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767.98px) {
  #dashboard-preview .preview-container {
    height: 350px;
    padding: 15px;
    border-radius: 8px;
  }
}
#dashboard-preview .preview-container .preview-container-slider {
  background: rgba(120, 128, 202, 0.4);
  backdrop-filter: blur(6.1px);
  -webkit-backdrop-filter: blur(6.1px);
  width: 100%;
  max-width: 864px;
  height: 100%;
  border-radius: 8px;
}
@media (max-width: 767.98px) {
  #dashboard-preview .preview-container .preview-container-slider {
    height: unset;
    max-height: 100%;
    border-radius: 3px;
  }
}
#dashboard-preview .preview-container .preview-container-slider .swiper-slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 8px;
}
@media (max-width: 767.98px) {
  #dashboard-preview .preview-container .preview-container-slider .swiper-slide img {
    border-radius: 3px;
  }
}

/* Sponsor Section */
#sponsor .sponsor-content-area {
  background-color: #898BFA;
  border-radius: var(--border-radius);
  padding: 50px 40px;
}
#sponsor .sponsor-content-area:lang(ar) {
  direction: ltr;
}
@media (max-width: 767.98px) {
  #sponsor .sponsor-content-area {
    padding: 27px 20px;
  }
}
#sponsor .sponsor-content-area .section-text {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
#sponsor .sponsor-content-area .section-text h2 {
  font-size: 2.2rem;
}
@media (max-width: 767.98px) {
  #sponsor .sponsor-content-area .section-text h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
}
#sponsor .sponsor-content-area .section-text p {
  font-size: 1rem;
}
@media (max-width: 767.98px) {
  #sponsor .sponsor-content-area .section-text p {
    font-size: 0.9rem;
  }
}
#sponsor .sponsor-content-area .section-text .learn-more {
  background-color: #fff;
  border: 1px solid #CBCFFF;
  color: #000;
  align-self: flex-start;
}
#sponsor .sponsor-content-area .section-text .learn-more:hover {
  background-color: #18014F;
  color: #fff;
  border-color: transparent;
}
#sponsor .sponsor-content-area .gif-preview {
  width: 100%;
  border-radius: var(--border-radius);
  background: rgba(145, 242, 255, 0.15);
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  text-align: right;
}
#sponsor .sponsor-content-area .gif-preview img {
  aspect-ratio: 16/9;
  border-radius: var(--border-radius);
  -o-object-fit: contain;
     object-fit: contain;
}
#sponsor .sponsor-content-area .circle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500px;
}

/* Module Section */
#module .module-cards-container .module-card {
  border: 1px solid #CBCFFF;
  padding: 90px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 25px;
  max-width: 530px;
  min-height: 500px;
  border-radius: var(--border-radius-sm);
  transition: all 0.2s ease-out;
}
#module .module-cards-container .module-card:hover {
  border-color: #6571ff;
}
@media (max-width: 991.98px) {
  #module .module-cards-container .module-card {
    padding: 70px 40px;
  }
}
#module .module-cards-container .module-card img {
  min-width: 80px;
  max-width: 80px;
}
#module .module-cards-container .module-card h2 {
  font-size: 2rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
#module .module-cards-container .module-card p {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  #module .module-cards-container .module-card p {
    font-size: 0.9rem;
  }
}

/* About Us Section */
#about-us-section .about-us-content-area {
  background: linear-gradient(103deg, rgb(255, 255, 255) 56%, rgb(203, 207, 255) 100%);
  padding: 50px 40px;
  border-radius: var(--border-radius);
  border: 1px solid #CBCFFF;
}
#about-us-section .about-us-content-area:lang(ar) {
  direction: ltr;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area {
    padding-bottom: 120px;
  }
}
#about-us-section .about-us-content-area .section-text h2 {
  font-size: 3.25rem;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .section-text h2 {
    font-size: 1.7rem;
  }
}
#about-us-section .about-us-content-area .section-text p {
  line-height: 32px;
  font-size: 1rem;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .section-text p {
    margin: auto;
    font-size: 0.9rem;
    line-height: 24px;
    width: 100% !important;
  }
}
@media (max-width: 991.98px) {
  #about-us-section .about-us-content-area .section-text {
    text-align: center;
  }
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .section-text {
    text-align: left;
  }
}
#about-us-section .about-us-content-area .about-card {
  position: relative;
  max-width: 480px;
  float: right;
  margin-right: 10%;
  /* Review Section */
}
@media (max-width: 991.98px) {
  #about-us-section .about-us-content-area .about-card {
    float: unset;
    margin: auto;
  }
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .about-card {
    float: unset;
    margin: auto;
    max-width: 350px;
  }
}
#about-us-section .about-us-content-area .about-card img {
  width: 100%;
  height: 100%;
}
#about-us-section .about-us-content-area .about-card .flow-content {
  position: absolute;
  top: 0;
}
#about-us-section .about-us-content-area .about-card .front-icon {
  left: -11%;
  top: 6%;
  width: 58%;
}
#about-us-section .about-us-content-area .about-card .front-icon:lang(ar) {
  left: unset;
  right: -11%;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .about-card .front-icon {
    left: -6%;
    top: -2%;
    width: 67%;
  }
  #about-us-section .about-us-content-area .about-card .front-icon:lang(ar) {
    left: unset;
    right: -6%;
  }
}
#about-us-section .about-us-content-area .about-card .front-icon img {
  width: 100%;
}
#about-us-section .about-us-content-area .about-card .review-section {
  display: flex;
  flex-direction: column;
  padding: 3.5%;
  border-radius: 10px;
  background-color: #fff;
  text-align: left;
  border: 1px solid #CBCFFF;
  top: 29%;
  right: -4%;
}
#about-us-section .about-us-content-area .about-card .review-section:lang(ar) {
  right: unset;
  left: -4%;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .about-card .review-section {
    top: 38%;
    right: 0%;
    width: 100%;
    padding: 10px;
  }
  #about-us-section .about-us-content-area .about-card .review-section:lang(ar) {
    right: unset;
    left: 0%;
  }
}
#about-us-section .about-us-content-area .about-card .review-section .review {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .about-card .review-section .review {
    gap: 6px;
  }
}
#about-us-section .about-us-content-area .about-card .review-section .review .review-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
#about-us-section .about-us-content-area .about-card .review-section .review .review-header .profile-img {
  width: 40px;
  border-radius: 50%;
}
#about-us-section .about-us-content-area .about-card .review-section .review .review-header .profile-img img {
  width: 100%;
  border-radius: 50%;
}
#about-us-section .about-us-content-area .about-card .review-section .review .review-header h4 {
  margin: 0;
  font-size: 1.1rem;
  color: #32343A;
  font-weight: 504;
}
#about-us-section .about-us-content-area .about-card .review-section .review .review-body p {
  margin: 0;
  line-height: 30px;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .about-card .review-section .review .review-body p {
    line-height: 25px;
    font-size: 0.7rem;
  }
}
#about-us-section .about-us-content-area .about-card .review-section .review .review-footer .stars {
  display: flex;
  width: 30px;
  gap: 15px;
  align-items: center;
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .about-card .review-section .review .review-footer .stars {
    gap: 12px;
    width: 22px;
  }
}
#about-us-section .about-us-content-area .about-card .total-resumes {
  background: linear-gradient(122deg, rgb(156, 164, 255) 54%, rgb(255, 255, 255) 100%);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: left;
  width: 85%;
  top: 84.5%;
  right: -12%;
  background: linear-gradient(122deg, rgba(156, 164, 255, 0.2) 68%, rgba(255, 255, 255, 0.2) 100%);
  box-shadow: 0 4px 30px rgba(68, 169, 216, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}
@media (max-width: 767.98px) {
  #about-us-section .about-us-content-area .about-card .total-resumes {
    border-radius: 18px;
    padding: 14px;
    width: 82%;
    top: 85.5%;
    right: 1%;
  }
}
#about-us-section .about-us-content-area .about-card .total-resumes .resumes-icon {
  margin-right: 15px;
}
#about-us-section .about-us-content-area .about-card .total-resumes .resumes-icon img {
  width: 40px;
}
#about-us-section .about-us-content-area .about-card .total-resumes .resumes-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
}
#about-us-section .about-us-content-area .about-card .total-resumes .resumes-text h5 {
  margin: 0;
  color: #4B56D2;
  font-size: 0.95rem;
  font-weight: 400;
}
#about-us-section .about-us-content-area .about-card .total-resumes .resumes-text .resumes-number {
  font-weight: 600;
}
#about-us-section .about-us-content-area .about-card .total-resumes .resumes-text .resumes-bar {
  width: 100%;
  background-color: #CBCFFF;
  border-radius: 4px;
  padding: 3px;
  position: relative;
}
#about-us-section .about-us-content-area .about-card .total-resumes .resumes-text .resumes-bar::after {
  content: "";
  width: 30%;
  background-color: #4B56D2;
  border-radius: 4px;
  padding: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

/* Call With Footer Section */
#cta-with-footer {
  background: linear-gradient(90deg, rgb(99, 109, 194) 0%, rgb(68, 79, 182) 100%);
  padding-inline: 20px;
  padding-top: 100px;
  padding-bottom: 20px;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  position: relative;
  z-index: 1;
}
@media (max-width: 991.98px) {
  #cta-with-footer {
    padding-top: 70px;
    padding-inline: 0;
  }
}
#cta-with-footer .style-icon {
  position: absolute;
  top: 8%;
  left: -2.5%;
  z-index: -1;
  width: 400px;
  opacity: 0.4;
}
#cta-with-footer .style-icon:lang(ar) {
  left: unset;
  right: -2.5%;
}
@media (max-width: 1199.98px) {
  #cta-with-footer .style-icon {
    display: none;
  }
}
#cta-with-footer .section-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 25px;
  width: 48%;
  margin: auto;
}
@media (max-width: 1199.98px) {
  #cta-with-footer .section-text {
    width: 80%;
    gap: 25px;
  }
}
@media (max-width: 767.98px) {
  #cta-with-footer .section-text {
    width: 100%;
    gap: 20px;
  }
}
#cta-with-footer .section-text h2 {
  font-size: 2.9rem;
}
@media (max-width: 991.98px) {
  #cta-with-footer .section-text h2 {
    font-size: 2.2rem;
  }
}
#cta-with-footer .section-text p {
  font-size: 1rem;
}
@media (max-width: 767.98px) {
  #cta-with-footer .section-text p {
    font-size: 0.9rem;
  }
}
#cta-with-footer footer {
  border: 1px solid #444FB6;
  box-shadow: 0px 6px 15px rgba(29, 29, 29, 0.3);
}